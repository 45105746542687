import React from 'react';
import Layout from '../components/layout';
import FavList from '../components/favList';
import SEO from "../components/seo"

const Cart = () => {
    return ( 
    <Layout>
        <SEO title="Wunschliste"></SEO>
    <FavList />
    </Layout> 
    );
}
 
export default Cart;
