import React, { useContext } from 'react';
import { FavContext } from '../context/FavContext';
import { Link } from "gatsby"
import Img from 'gatsby-image'

import styled from 'styled-components'


const Container = styled.article`
  min-height: 100vh;
  padding: 16px;
  margin-bottom: 10rem;
  @media (min-width: 600px) {
    padding: 26px;
    margin-bottom: 0;
  }
`
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 600px) {
  }
`

const FavoritesWrapper = styled.div`
  flex: 100%;  
`



const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 600px) {
  }
`
const Li = styled.li`
  text-align: center;
  flex: 1 1 330px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    margin: 5px;
    max-width: 25%;
  }
`

const Details = styled.div`
  padding: 16px;
  background-color: #FBF8F5;
`
const Headline = styled.h3`
  margin-bottom: 6px;
  letter-spacing: 2px;
`
const Description = styled.div`
  margin-bottom: 6px;
  letter-spacing: 1.5px;
`
const Button = styled.button`
  margin-top: 8px;
  border: none;
  background: inherit;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const FavList = () => {
    const { favs, removeFav } = useContext(FavContext);
    return(
    <Container>
      <h2 style={{marginBottom: "3rem", fontWeight: "600", fontSize: "2.5rem"}}>Merkzettel</h2>
      {favs.length ? (
        <>
        <Flex>
        <FavoritesWrapper>
        
          <Ul>
            {favs.map(fav =>
              <Li key={fav.pageID}>
                <Link to={fav.slug}>
                  {/* <ImgWrapper> */}
                    <Img fluid={fav.img} alt="" />
                  {/* </ImgWrapper> */}
                </Link>
                <Details>
                  <Link to={fav.slug}>
                    <Headline>{fav.name}</Headline>
                  </Link>
                  <Description>{fav.model}</Description>
                  
                  <Description>ab {fav.price}€</Description>
                  <Button onClick={() => removeFav(fav.id)}>Entfernen</Button>
                </Details>
              </Li>
            )}
          </Ul>
          </FavoritesWrapper>
          {/* <FormWrapper>
          <h2 style={{paddingLeft: "30px" ,marginBottom: "3rem"}}>Unverbindlich Anfrage für Ihre Favoriten</h2>
          <MultiProductForm
          formData={formData}
          />
          </FormWrapper> */}
          </Flex>
          </>
      ) : (
          <div style={{ height: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>Bisher haben Sie keine Artikel zu Ihrem Merkzettel hinzugefügt.</div>
        )}
    </Container>
  )
}


 
export default FavList;